



























































import { Vue, Component } from "vue-property-decorator";
import { api_user, add_user } from "@/http/user";
import PageHeader from "@/components/pageHeader/index.vue";
import { ElForm } from "element-ui/types/form";
import { api_store, store } from "@/http/store";
import {
  provinceAndCityData,
  regionData,
  CodeToText,
} from "element-china-area-data";

@Component({
  components: {
    PageHeader,
  },
})
export default class extends Vue {
  info: add_user = {
    id: "",
    phone: "",
    wallet: "",
    nickname: "用户名",
    avatar_url: "icon/PixPin_1.png",
    store: "",
  };

  options = provinceAndCityData;
  selectedOptions: string[] = [];

  rules = {
    name: [{ required: true, message: "请填写分类名称" }],
    wallet: [{ required: true, message: "请填写充值金额" }, {
      validator: (rule:any, value:any, callback:any) => {
        if (value < 0) {
          callback(new Error("充值金额不能为负数"));
        } else {
          callback();
        }
      },
      trigger: ["blur", "change"]
    }
  ],
    store: [{ required: true, message: "请选择门店" }],
    phone: [
    { required: true, message: "请填写手机号码", trigger: "blur" },
    {
      pattern: /^(?:(?:\+|00)86)?1\d{10}$/,
      message: "请输入有效的手机号码",
      trigger: ["blur", "change"]
    }
  ]
  };

  //   handleChange() {
  //     this.info.cityCode = this.selectedOptions[1];
  //     this.info.provinceCode = this.selectedOptions[0];
  //     regionData.forEach((item: any) => {
  //       if (item.value == this.info.provinceCode) {
  //         item.children.forEach((e: any) => {
  //           if (e.value === this.info.cityCode) {
  //             this.info.region = e.children;
  //           }
  //         });
  //       }
  //     });
  //     this.info.city =
  //       CodeToText[this.selectedOptions[1]] === "市辖区"
  //         ? CodeToText[this.selectedOptions[0]]
  //         : CodeToText[this.selectedOptions[1]];
  //     this.info.province = CodeToText[this.selectedOptions[0]];
  //   }

  async submit() {
    await (this.$refs["form"] as ElForm | undefined)?.validate();
    if (this.id) {
      await api_user.edit(this.info);
    } else {
      await api_user.create(this.info);
    }
    this.$router.go(-1);
  }

  async get_info() {
    this.info = await api_user.get_info(this.id as number);
    // this.options = options;
  }

  get id(): undefined | number {
    return this.$route.query.id as any;
  }

  // 获取门店列表
  storeList: any[] = [];
  async get_store_list() {
    const res = await api_store.get_list({ page_size: 10000 });
    const list: any[] = [];
    res.results.forEach((item: any) => {
      (item.value = item.id), (item.label = item.name);
    });
    this.storeList = list.concat(res.results);
  }

  created() {
    this.get_store_list();
    if (this.id === undefined) return;
    this.get_info();
  }
}
